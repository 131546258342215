<div
  class="add-company-variants"
  style="max-width: 704px"
  [formGroup]="titleVariantsForm"
>
  <div class="add-company-title-container">
    <div
      class="add-company-title-container__nav"
      *ngIf="editable !== 'titleVariants'"
    >
      {{editable}}
      <button class="add-company-title-container__back" (click)="prevStep()">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
      <button class="add-company-title-container__next" (click)="nextStep()" [disabled]="!continueButton">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
    </div>
    <h2 class="add-company-title">
      {{ "add-company-page.title-variants.title.1" | translate }}
      <br />
      {{ "add-company-page.title-variants.title.2" | translate }}
    </h2>
  </div>
  <div class="add-company-step">
    <div class="add-company-step__text">3/5</div>
  </div>
  <ng-content select="[loading]"></ng-content>
  <a class="pink-link" href="">
    <img src="assets/icons/pink-link.svg" alt="link-pic" />
    <p>{{'add-company-page.title-variants.hint' | translate}}</p>
  </a>
  <div class="add-company-variants__content">
    <div class="add-company-variants__content__inputs">
      <div class="add-company-variants__input">
        <label class="add-company-variants__input__label"
          >{{'add-company-page.title-variants.firstVariant' | translate}}</label
        >
        <input
          type="text"
          formControlName="variant1"
          maxlength="60"
          [ngClass]="this.titleVariantsForm.value.variant1.length && this.titleVariantsForm.value.variant1.length < 2 ? 'input-error' : ''"
        />
        <p
          class="add-company-variants__input__error"
          *ngIf="this.titleVariantsForm.value.variant1.length && this.titleVariantsForm.value.variant1.length < 2"
        >
          {{'add-company-page.title-variants.titleErrorLength' | translate}}
        </p>
      </div>
      <div class="add-company-page__active-dropdown">
        <label>{{'add-company-page.title-variants.suffix' | translate}}</label>
        <app-dropdown-type-1
          [name]="
          titleVariantsForm.value.suffix1
                  ? titleVariantsForm.value.suffix1
                  : ('add-company-page.title-variants.suffix' | translate)
              "
          [nameDefault]="'add-company-page.title-variants.suffix' | translate"
          [elements]="suffixes"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setSuffix1($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown == '1' ? true : false"
          [dropdown]="'1'"
        />
      </div>
      <div class="add-company-variants__input">
        <label class="add-company-variants__input__label"
          >{{'add-company-page.title-variants.secondVariant' |
          translate}}</label
        >
        <input
          type="text"
          formControlName="variant2"
          maxlength="60"
          [ngClass]="this.titleVariantsForm.value.variant2.length && this.titleVariantsForm.value.variant2.length < 2 ? 'input-error' : ''"
        />
        <p
          class="add-company-variants__input__error"
          *ngIf="this.titleVariantsForm.value.variant2.length && this.titleVariantsForm.value.variant2.length < 2"
        >
          {{'add-company-page.title-variants.titleErrorLength' | translate}}
        </p>
      </div>
      <div class="add-company-page__active-dropdown">
        <label>{{'add-company-page.title-variants.suffix' | translate}}</label>
        <app-dropdown-type-1
          [name]="
          titleVariantsForm.value.suffix2
                  ? titleVariantsForm.value.suffix2
                  : ('add-company-page.title-variants.suffix' | translate)
              "
          [nameDefault]="'add-company-page.title-variants.suffix' | translate"
          [elements]="suffixes"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setSuffix2($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown == '2' ? true : false"
          [dropdown]="'2'"
        />
      </div>
      <div class="add-company-variants__input">
        <label class="add-company-variants__input__label"
          >{{'add-company-page.title-variants.thirdVariant' | translate}}</label
        >
        <input
          type="text"
          formControlName="variant3"
          maxlength="60"
          [ngClass]="this.titleVariantsForm.value.variant3.length && this.titleVariantsForm.value.variant3.length < 2 ? 'input-error' : ''"
        />
        <p
          class="add-company-variants__input__error"
          *ngIf="this.titleVariantsForm.value.variant3.length && this.titleVariantsForm.value.variant3.length < 2"
        >
          {{'add-company-page.title-variants.titleErrorLength' | translate}}
        </p>
      </div>
      <div class="add-company-page__active-dropdown">
        <label>{{'add-company-page.title-variants.suffix' | translate}}</label>
        <app-dropdown-type-1
          [name]="
          titleVariantsForm.value.suffix3
                  ? titleVariantsForm.value.suffix3
                  : ('add-company-page.title-variants.suffix' | translate)
              "
          [nameDefault]="'add-company-page.title-variants.suffix' | translate"
          [elements]="suffixes"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setSuffix3($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown == '3' ? true : false"
          [dropdown]="'3'"
        />
      </div>
    </div>
  </div>
  <ng-content select="[price]"></ng-content>
  <button
    class="Button-blue add-company-page__submit-nonActive"
    [ngClass]="continueButton ? 'add-company-page__submit' : ''"
    (click)="submitStructure()"
    [disabled]="!continueButton"
  >
    {{'continue-btn' | translate}}
  </button>
</div>

<button 
  type="button" 
  class="save-template-button"
  [ngClass]="!continueButton ? 'nonActive' : ''"
  (click)="submitStructure()" 
  [disabled]="!continueButton"
>{{ "add-company-page.saveToDrafts" | translate }}</button>
