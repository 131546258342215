import { Component, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CityData, CountryData } from 'app/store/Shared/model/SharedModel';
import {
  getCitiesByCountry,
  getCitiesByCountrySuccess,
  updateTemplate,
  updateTemplateSuccess,
} from 'app/store/Shared/shared.actions';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { AppState } from 'app/store/app.state';
import { BehaviorSubject } from 'rxjs';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-add-company-title-variants',
  templateUrl: './ui/VariantsOfTitle.html',
  styleUrls: ['./ui/VariantsOfTitle.scss'],
})
export class AddCompanyVariantsOfTitle {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private rootFormGroup: FormGroupDirective,
    private translateService: TranslateService,
    private actions: Actions,
  ) {}
  @Input() formGroupName: string;
  userInfo: UserInfoProps | null;
  haveAdress: boolean = false;
  titleVariantsForm: FormGroup;
  countries: CountryData[];
  cities: CityData[];
  editable: string;

  subs: Subscription[] = [];

  @Input() nextStep: any;
  @Input() prevStep: any;
  @Input() prewiewStep: any;

  dropdown: string = '0';

  //suffixes
  @Input() suffixes = [{ title: 'LIMITED' }, { title: 'LTD' }];

  //cities and countries
  suffix1 = new BehaviorSubject<any>('');
  suffix2 = new BehaviorSubject<any>('');
  suffix3 = new BehaviorSubject<any>('');
  //continue button
  continueButton: boolean = false;

  saveMode: boolean | undefined = false;

  setSuffix1(elem: any) {
    this.suffix1.next(elem);
    this.titleVariantsForm.controls.suffix1.setValue(this.suffix1.value);
  }
  setSuffix2(elem: any) {
    this.suffix2.next(elem);
    this.titleVariantsForm.controls.suffix2.setValue(this.suffix2.value);
  }
  setSuffix3(elem: any) {
    this.suffix3.next(elem);
    this.titleVariantsForm.controls.suffix3.setValue(this.suffix3.value);
  }

  ngOnInit() {
    //initialization of root form group
    this.titleVariantsForm = this.rootFormGroup.control.get(
      this.formGroupName
    ) as FormGroup;

    this.titleVariantsForm.valueChanges.subscribe((data) => {
      this.haveAdress = data.haveAnAdress;
      //clears cities on change have address
    });

    this.suffix1.next(this.titleVariantsForm.value.suffix1);
    this.suffix2.next(this.titleVariantsForm.value.suffix2);
    this.suffix3.next(this.titleVariantsForm.value.suffix3);

    if (
      this.suffixes
        .map((suffix) => suffix.title)
        .indexOf(this.titleVariantsForm.value.suffix1) == -1
    ) {
      this.suffix1.next('');
    } else if (
      this.suffixes
        .map((suffix) => suffix.title)
        .indexOf(this.titleVariantsForm.value.suffix1) >= 0
    ) {
      this.suffix1.next(this.titleVariantsForm.value.suffix1);
    }
    if (
      this.suffixes
        .map((suffix) => suffix.title)
        .indexOf(this.titleVariantsForm.value.suffix2) == -1
    ) {
      this.suffix2.next('');
    } else if (
      this.suffixes
        .map((suffix) => suffix.title)
        .indexOf(this.titleVariantsForm.value.suffix2) >= 0
    ) {
      this.suffix2.next(this.titleVariantsForm.value.suffix2);
    }
    if (
      this.suffixes
        .map((suffix) => suffix.title)
        .indexOf(this.titleVariantsForm.value.suffix3) == -1
    ) {
      this.suffix3.next('');
    } else if (
      this.suffixes
        .map((suffix) => suffix.title)
        .indexOf(this.titleVariantsForm.value.suffix3) >= 0
    ) {
      this.suffix3.next(this.titleVariantsForm.value.suffix3);
    }

    //setting counties array
    this.store
      .select((state) => state.shared.countries)
      .subscribe((countries) => {
        if (countries) {
          this.countries = countries;
        }
      });
    //cities array
    this.store
      .select((state) => state.shared.citiesByCoutry)
      .subscribe((cities) => {
        if (cities) {
          this.cities = cities;
        }
      });
    //editable
    this.store
      .select((state) => state.shared.editableCompanyStep)
      .subscribe((editable) => {
        if (editable) {
          this.editable = editable;
        }
      });
    //setting virtual to undefined when ihaveanaddress switches
    this.titleVariantsForm.valueChanges.subscribe((data) => {
      this.setContinueBtn();
      console.log(data);
    });
    this.setContinueBtn();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  //sets have an adress form value
  setHaveAnAdress = () => {
    this.titleVariantsForm.controls.haveAnAdress.setValue(true);
    console.log(this.titleVariantsForm.controls.haveAnAdress.value);
  };

  setContinueBtn = () => {
    if (
      this.titleVariantsForm.value.variant1 !== '' &&
      this.titleVariantsForm.value.variant2 !== '' &&
      this.titleVariantsForm.value.variant3 !== '' &&
      this.titleVariantsForm.value.suffix1 !== '' &&
      this.titleVariantsForm.value.suffix2 !== '' &&
      this.titleVariantsForm.value.suffix3 !== ''
    ) {
      this.continueButton = true;
    } else {
      this.continueButton = false;
    }
  };
  setDropdown(drop: string): void {
    this.dropdown = drop;
  }

  //button-back
  buttonBack = () => {};

  //submits structere and forfards step
  submitStructure = (saveMode?: boolean) => {
    this.saveMode = saveMode;
    let data = this.prepairValues();
    this.subs.push(
      this.store
        .select((state) => state.shared.currentTemplateId)
        .subscribe((id) => {
          if (id) {
            this.store.dispatch(updateTemplate({
              data: {
                titleVariants: data,
                processStep: 4
              },
              id: id
            }));
          }
        })
    )

    this._listenForUpdateSuccess();
    // if (!saveMode) {
    //   this.editable == 'titleVariants' ? this.prewiewStep() : this.nextStep();
    // }
  };

  private _listenForUpdateSuccess() {
    this.subs.push(
      this.actions
        .pipe(ofType(updateTemplateSuccess))
        .subscribe(() => {
          if (!this.saveMode) {
            this.editable == 'titleVariants' ? this.prewiewStep() : this.nextStep();
          }
        })
    );  
  }

  private prepairValues() {
    return [
      {
        prefix: this.titleVariantsForm.value.suffix1,
        title: this.titleVariantsForm.value.variant1
      },
      {
        prefix: this.titleVariantsForm.value.suffix2,
        title: this.titleVariantsForm.value.variant2
      },
      {
        prefix: this.titleVariantsForm.value.suffix3,
        title: this.titleVariantsForm.value.variant3
      }
    ]
  }
}
