import { state } from '@angular/animations';
import { Component } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  CompanyData,
  CompanyDraftData,
  CompanyDraftResponceData,
  CountryData,
  MacroregionData,
  companyOption,
} from 'app/store/Shared/model/SharedModel';
import {
  addCompanyDraftDataState,
  getCitiesByCountry,
  getCompanyOptions,
  registerCompany,
  setAddCompanyStep,
  getCompanyById,
  getCompanyByIdSuccess,
  clearCompanyId
} from 'app/store/Shared/shared.actions';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { AppState } from 'app/store/app.state';
import { BehaviorSubject, pairwise } from 'rxjs';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';

@Component({
  selector: 'app-add-company-page-',
  templateUrl: './ui/AddCompanyPage.component.html',
  styleUrls: ['./ui/AddCompanyPage.component.scss'],
})
export class AddCompanyPage {
  userInfo: UserInfoProps | null;
  name: string = '';
  companyForm: FormGroup;
  jurisdictionalForm: string;
  numberOfDirectors: string = '0';
  numberOfFounders: string = '0';
  selectedCountryId: string = 'FD-123';
  titlePrefixes: DropdownType1Eement[];

  countries: CountryData[];
  macroregions: MacroregionData[];

  step: number = 1;
  companyOptions: companyOption[];
  public companyOption = new BehaviorSubject<companyOption | undefined>(
    undefined
  );
  price: number = 0;
  renewPrice: number = 0;
  regPrice: number = 0;
  currency: string | undefined;
  loading: boolean = false;

  subs: Subscription[] = [];

  directors: any;
  founders: any;

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    //form for company info
    this.companyForm = this.fb.group({
      jurisdiction: this.fb.group({
        region: this.fb.control('', Validators.required),
        country: this.fb.control('', Validators.required),
      }),
      structure: this.fb.group({
        jurisdictionalForm: this.fb.control('Ug', Validators.required),
        numberOfDirectors: this.fb.control('1', Validators.required),
        numberOfFounders: this.fb.control('1', Validators.required),
        capital: this.fb.control('20000', Validators.required),
      }),
      address: this.fb.group({
        region: this.fb.control('', Validators.required),   //
        country: this.fb.control('', Validators.required),
        country2: this.fb.control('', Validators.required),
        city: this.fb.control('', Validators.required),
        city2: this.fb.control('', Validators.required),
        real: this.fb.control('', Validators.required),
        haveAnAdress: this.fb.control(false, Validators.required),
        address1: this.fb.control('', Validators.required), //
        // address2: this.fb.control('', Validators.required), //
        postalCode: this.fb.control('', Validators.required),
      }),
      titleVariants: this.fb.group({
        variant1: this.fb.control('', Validators.required),
        variant2: this.fb.control('', Validators.required),
        variant3: this.fb.control('', Validators.required),
        suffix1: this.fb.control('', Validators.required),
        suffix2: this.fb.control('', Validators.required),
        suffix3: this.fb.control('', Validators.required),
      }),
      directorsGroup: this.fb.group({
        directors: this.fb.array([]),
      }),
      foundersGroup: this.fb.group({
        legal: this.fb.array([]),
      }),
    });

     //setting counties array
    this.store
      .select((state) => state.shared.countries)
      .subscribe((countries) => {
        if (countries) {
          this.countries = countries;

          let ger = this.countries.find((c: any) => c.title === 'Germany')
          this.companyForm.get('address')!.patchValue({
            region: ger ? ger.macroRegion : 'se',
            country: ger ? ger.id : 'FD-123',
            country2: ger ? ger.id : 'FD-123',
          });
          this.selectedCountryId = ger ? ger.id : 'FD-123';
        }
      });

    //setting regions array
    this.store
      .select((state) => state.shared.macroregions)
      .subscribe((macroRegions) => {
        if (macroRegions) {
          this.macroregions = macroRegions;
        }
      });

    if (this.route.snapshot.queryParams['id']) {
      this._getCompanyInfo(this.route.snapshot.queryParams['id']);
    }

    //sets user info
    this.store
      .select((state) => state.userinfo)
      .subscribe((info) => {
        this.userInfo = info.UserInfoData;
      });
    //sets user name
    this.store
      .select((state) => state.userinfo)
      .subscribe((state) => {
        // if (state.UserInfoData) {
        //   this.name = `${state.UserInfoData.firstName} ${state.UserInfoData.lastName}`;
        // }
      });

    // getting step state
    this.store
      .select((state) => state.shared.addCompanyStep)
      .subscribe((step) => {
        this.step = step;
      });

    //get all company options
    this.store.dispatch(getCompanyOptions());

    //getting options state
    this.store
      .select((state) => state.shared.companyOptions)
      .subscribe((options) => {
        if (options) {
          this.companyOptions = options;

          let newOption = this.companyOptions?.find(
            (option: companyOption) => this.selectedCountryId == option.countryId
          );
          if (this.companyOptions && newOption !== this.companyOption.value) {
            this.companyOption.next(newOption);
          }
        }
      });

    this.companyForm.valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        console.log(next);
        let macroId = '';
        let directors: {
          nationality: string;
          passport: string;
          isOwner: boolean;
          fullname: string;
        }[] = [];
        let shareholdersPhysical: {
          nationality: string;
          passport: string;
          share: number;
          fullname: string;
        }[] = [];
        let shareholdersCompany: {
          country: string;
          number: string;
          share: number;
          form: string;
          title: string;
        }[] = [];
        if (next.address.haveAnAdress) {
          let country = this.countries.find(
            (country) => country.id === next.country
          );
          let macro = this.macroregions.find(
            (macro) => macro.id === next.address.region
          );
          if (country?.id) {
            this.selectedCountryId = country.id;
          }
          if (macro?.id) {
            macroId = macro.id;
          }
        }
        if (!next.address.haveAnAdress) {
          let country = this.countries?.find(
            (country) => country.id === next.address.country2
          );
          let macro = this.macroregions?.find(
            (macro) => macro.id === next.address.region
          );
          if (country?.id) {
            this.selectedCountryId = country.id;
          }
          if (macro?.id) {
            macroId = macro.id;
          }
        }

        let newOption = this.companyOptions?.find(
          (option: companyOption) => this.selectedCountryId == option.countryId
        );
        if (this.companyOptions && newOption !== this.companyOption.value) {
          this.companyOption.next(newOption);
        }
        console.log(prev.address, next.address);
        if (prev.address.country !== next.address.country) {
          let countryFound = this.countries.find(
            (c) => next.address.country == c.title
          );
          const countryId = countryFound?.id;
          if (countryId) {
            this.store.dispatch(getCitiesByCountry({ countryId: countryId }));
          }
        }

        next.directorsGroup.directors.map((director: any, index: any) => {
          directors.push({
            nationality: director.citizenship,
            passport: director.passport,
            isOwner: director.founder,
            fullname: director.nameAndLastName,
          });
        });
        next.foundersGroup.legal.map((founder: any) => {
          if (!founder.legal) {
            shareholdersPhysical.push({
              nationality: founder.citizenship,
              passport: founder.passport,
              share: founder.share, // потом надо изменить
              fullname: founder.nameAndLastName,
            });
          }
          if (founder.legal) {
            shareholdersCompany.push({
              country: founder.country,
              number: founder.regNumber,
              share: founder.share, // потом надо изменить
              form: founder.form,
              title: founder.name,
            });
          }
        });
        let draftData: CompanyData = {
          registrationOptionId: 'OPT-001',
          // macroRegionId: macroId,
          shareholdersCount: next.structure.numberOfFounders,
          directorsCount: next.structure.directorsCount,
          // countryId: this.selectedCountryId,
          //legalFormId: next.structure.jurisdictionalForm,
          legalFormId: "FORM64377147-G69LPG-789155",
          authorizedCapital: next.structure.capital,
          titleVariants: [
            {
              prefix: next.titleVariants.suffix,
              title: next.titleVariants.variant1,
            },
            {
              prefix: next.titleVariants.suffix,
              title: next.titleVariants.variant2,
            },
            {
              prefix: next.titleVariants.suffix,
              title: next.titleVariants.variant3,
            },
          ],

          director: { ...directors },

          shareholderPhysical: { ...shareholdersPhysical },

          shareholderCompany: { ...shareholdersCompany },
        };
        this.store.dispatch(addCompanyDraftDataState({ data: draftData }));
      });

    //cities array
    this.store
      .select((state) => state.shared.citiesByCoutry)
      .subscribe((cities) => {
        console.log(cities);
        if (cities) {
          if (
            cities
              .map((city) => city.id)
              .indexOf(this.companyForm.value.address.city) == -1
          ) {
            this.companyForm.controls.address.setValue({
              ...this.companyForm.value.address,
              city: '',
            });
          }
          if (
            cities
              .map((city) => city.id)
              .indexOf(this.companyForm.value.address.city2) == -1
          ) {
            this.companyForm.controls.address.setValue({
              ...this.companyForm.value.address,
              city2: '',
            });
          }
        }
      });

    this.companyForm.controls.structure.valueChanges.subscribe((data) => {
      let form = this.companyOption.value?.legalForms.find(
        (form) => data.jurisdictionalForm == form.legalFormId
      );
      if (
        form?.titlePrefixes &&
        form?.titlePrefixes.map((title) => {
          return { title: title };
        }) !== this.titlePrefixes
      ) {
        this.titlePrefixes = form?.titlePrefixes.map((title) => {
          return { title: title };
        });
        if (
          form?.titlePrefixes.indexOf(
            this.companyForm.value.titleVariants.suffix1
          ) == -1
        ) {
          this.companyForm.controls.titleVariants.setValue({
            ...this.companyForm.value.titleVariants,
            suffix1: '',
            suffix2: '',
            suffix3: '',
          });
        }
      } else if (!form?.titlePrefixes) {
        this.titlePrefixes = [];
        this.companyForm.controls.titleVariants.setValue({
          ...this.companyForm.value.titleVariants,
          suffix1: '',
          suffix2: '',
          suffix3: '',
        });
      }
    });
    this.companyOption.subscribe((value) => {
      let regPrice = value?.legalForms[0].registrationPrice || 0;
      let directorPrice = value?.legalForms[0].oneDirectorCost || 0;
      let founderPrice = value?.legalForms[0].oneOwnerCost || 0;
      let renewPrice = value?.legalForms[0].renewPrice || 0;

      let jurForms: DropdownType1Eement[] | undefined =
        value?.legalForms.map<DropdownType1Eement>((form) => {
          console.log({ title: form.title });
          return { title: form.title, id: form.legalFormId };
        });
      if (
        jurForms &&
        jurForms
          .map((form) => form.id)
          .indexOf(this.companyForm.value.structure.jurisdictionalForm) == -1
      ) {
        let structureForm = this.companyForm.get('structure') as FormGroup;
        structureForm.setValue({
          ...this.companyForm.value.structure,
          jurisdictionalForm: '',
        });
      }
      if (!jurForms) {
        let structureForm = this.companyForm.get('structure') as FormGroup;
        structureForm.setValue({
          ...this.companyForm.value.structure,
          jurisdictionalForm: '',
        });
      }
      let fullDirPrice =
        Number(this.companyForm.value.structure.numberOfDirectors) *
          directorPrice || 0;
      let fullOwnerPrice =
        Number(this.companyForm.value.structure.numberOfFounders) *
          founderPrice || 0;
      let isRenew = 0;
      if (
        !this.companyForm.value.address.haveAnAdress &&
        this.companyForm.value.address.real === '1'
      ) {
        isRenew = renewPrice;
      }
      this.price = fullOwnerPrice + fullDirPrice + isRenew + regPrice;
      this.renewPrice = isRenew;
      this.regPrice = regPrice;
      this.currency = value?.currency;
    });
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
    this.store.dispatch(clearCompanyId());
    this.store.dispatch(setAddCompanyStep({ step: 1 }));
  }

  setStructureJurisdictionalForm = (value: string) => {
    this.jurisdictionalForm = value;
  };
  setNumberOfDirectors = (value: string) => {
    this.numberOfDirectors = value;
  };
  setNumberOfFounders = (value: string) => {
    this.numberOfFounders = value;
  };

  nextStep = () => {
    let newStep = this.step + 1;
    this.store.dispatch(setAddCompanyStep({ step: newStep }));
  };
  prewiewStep = () => {
    let newStep = this.step + 1;
    this.store.dispatch(setAddCompanyStep({ step: 6 }));
  };
  prevStep = () => {
    let newStep = this.step - 1;
    this.store.dispatch(setAddCompanyStep({ step: newStep }));
  };
  toSpecificStep = (step: number) => {
    let newStep = step;
    this.store.dispatch(setAddCompanyStep({ step: newStep }));
  };

  createDirectorFormGroup(): FormGroup {
    return new FormGroup({
      nameAndLastName: new FormControl('', Validators.required),
      citizenship: new FormControl('', Validators.required),
      passport: new FormControl('', Validators.required),
      rights: new FormControl(false, Validators.required),
      founder: new FormControl(false, Validators.required),
      share: new FormControl('0', Validators.required),
    });
  }
  createFounderFormGroup(f?: any, solo?: false): FormGroup {
    return new FormGroup({
      legal: new FormControl(false, Validators.required),
      country: new FormControl('', Validators.required),
      form: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      regNumber: new FormControl('', Validators.required),
      nameAndLastName: new FormControl(
        f ? f.nameAndLastName : '',
        Validators.required
      ),
      citizenship: new FormControl(f ? f.citizenship : '', Validators.required),
      passport: new FormControl(f ? f.passport : '', Validators.required),
      share: new FormControl(f ? f.share : solo ? '100' : '1', Validators.required),
    });
  }
  createFounderIndividualFormGroup(): FormGroup {
    return new FormGroup({});
  }

  addDirectorFormGroup = () => {
    const emails = this.companyForm.controls.directorsGroup.get(
      'directors'
    ) as FormArray;
    emails.push(this.createDirectorFormGroup());
  };

  addFoundersFormGroup = (f?: any, solo?: false) => {
    const form = this.companyForm.controls.foundersGroup.get(
      'legal'
    ) as FormArray;
    form.push(this.createFounderFormGroup(f, solo));
  };

  private _getCompanyInfo(id: string) {
    this.loading = true;
    this.store.dispatch(getCompanyById({id}));

    this.subs.push(
      this.actions
      .pipe(ofType(getCompanyByIdSuccess))
      .subscribe(({ data }) => {
        if (data.processStep) {
          this.step = data.processStep;
        }
        this.selectedCountryId = data.countryId;
        let addressValues = {
          postalCode: data.companyAddress?.zip,
          address1: data.companyAddress?.street,
          real: data.companyAddress?.type,
          haveAnAdress: data.companyAddress?.type === 3,
          city: data.companyAddress?.type === 3 ? data.companyAddress?.city?.cityId : '',
          city2: data.companyAddress?.type !== 3 ? data.companyAddress?.city?.cityId : '',
          region: data.macroRegionId,
          country: data.countryId,
          country2: data.countryId,
        };
        this.companyForm.controls.address.patchValue(addressValues);
        if (data.companyAddress) {
          this.store.dispatch(getCitiesByCountry({ countryId: data.companyAddress.country.countryId }));
        }

        let directorsValues = {
          citizenship: data.director?.length && data.director[0]?.nationality,
          passport: data.director?.length && data.director[0]?.passport,
          founder: data.director?.length && data.director[0]?.isOwner || false,
          nameAndLastName: data.director?.length && data.director[0]?.fullname
        };
        ((this.companyForm.controls.directorsGroup as any).controls.directors as FormArray).push(this.fb.group(directorsValues));
        
        for (let i = 0; i < data.shareholdersCount; i++) {
          let foundersValues = {
            citizenship: data.shareholderPhysical?.length && data.shareholderPhysical[i]?.nationality,
            passport: data.shareholderPhysical?.length && data.shareholderPhysical[i]?.passport,
            nameAndLastName: data.shareholderPhysical?.length && data.shareholderPhysical[i]?.fullname,

            form: data.shareholderCompany?.length  && data.shareholderCompany[i]?.form,
            name: data.shareholderCompany?.length  && data.shareholderCompany[i]?.title,
            country: data.shareholderCompany?.length  && data.shareholderCompany[i]?.country,
            regNumber: data.shareholderCompany?.length  && data.shareholderCompany[i]?.number,

            share: data.shareholderPhysical?.length && data.shareholderPhysical[i]?.share || data.shareholderCompany?.length && data.shareholderCompany[i].share,
            legal: data.shareholderPhysical?.length && data.shareholderPhysical[i]?.share > 0 ? false : true
          };
          ((this.companyForm.controls.foundersGroup as any).controls.legal as FormArray).push(this.fb.group(foundersValues));
        }

        let structureValues = {
          //data.registrationOptionId
          capital: data.authorizedCapital || 20000,
          jurisdictionalForm: data.legalFormId,
          numberOfDirectors: data.directorsCount || 1,
          numberOfFounders: data.shareholdersCount || 1
        };
        this.companyForm.controls.structure.patchValue(structureValues);
        
        let variantsValies = {
          suffix1: data.titleVariants?.length && data.titleVariants[0]?.prefix,
          variant1: data.titleVariants?.length && data.titleVariants[0]?.title,
          suffix2: data.titleVariants?.length && data.titleVariants[1]?.prefix,
          variant2: data.titleVariants?.length && data.titleVariants[1]?.title,
          suffix3: data.titleVariants?.length && data.titleVariants[2]?.prefix,
          variant3: data.titleVariants?.length && data.titleVariants[2]?.title,
        }
        this.companyForm.controls.titleVariants.patchValue(variantsValies);

        this.loading = false;
      })
    );
  }
}
