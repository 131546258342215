<div
  class="add-company-founders"
  style="max-width: 704px"
  [formGroup]="foundersForm"
>
  <div class="add-company-title-container">
    <div
      class="add-company-title-container__nav"
      *ngIf="editable !== 'founders'"
    >
      <button class="add-company-title-container__back" (click)="prevStep()">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
    </div>
    <h2 class="add-company-title">
      {{ "add-company-page.founders.title.1" | translate }}
      <br />
      {{ "add-company-page.founders.title.2" | translate }}
    </h2>
  </div>
  <div class="add-company-step">
    <div class="add-company-step__text">5/5</div>
  </div>
  <ng-content select="[loading]"></ng-content>
  <div class="add-company-founders__content" formArrayName="legal">
    <div
      class="add-company-founders__content__founder"
      *ngFor="let group of numberOfFounders; let i = index"
      [formGroupName]="i"
    >
      <p class="add-company-founders__content__founder__label">
        {{'add-company-page.founders.founder' | translate}} № {{i + 1}}
      </p>
      <div class="add-company-founders__content__founder__legacy-btns">
        <button
          class="add-company-founders__rights-btn"
          [ngClass]="legalFoundersFormArray.value[i].legal ? 'add-company-founders__rights-btn-disabled' : ''"
          (click)="setLegalEntity(i, false)"
        >
          {{'add-company-page.founders.individualEntity' | translate}}
        </button>
        <button
          class="add-company-founders__rights-btn"
          (click)="setLegalEntity(i, true)"
          [ngClass]="!legalFoundersFormArray.value[i].legal ? 'add-company-founders__rights-btn-disabled' : ''"
          [disabled]="i === 0 && directorsFormArray.value[0].founder"
        >
          {{ 'add-company-page.founders.legalEntity' | translate}}
        </button>
      </div>
      <div
        *ngIf="!legalFoundersFormArray.value[i].legal"
        class="add-company-founders__content__founder__inputs"
      >
        <div class="add-company-founders__input">
          <label class=""
            >{{'add-company-page.founders.firstAndLastName'| translate}}</label
          >
          <input type="text" formControlName="nameAndLastName" [readonly]="i === 0 && directorsFormArray.value[0].founder"/>
        </div>

        <div class="add-company-founders__input">
          <label class=""
            >{{'add-company-page.founders.citizenship'| translate}}</label
          >
          <input type="text" formControlName="citizenship" [readonly]="i === 0 && directorsFormArray.value[0].founder"/>
          <!--<app-dropdown-type-1
            [name]="
            legalFoundersFormArray.value[i].citizenship
              ? getCountryName(legalFoundersFormArray.value[i].citizenship)
              : ('add-company-page.address.country' | translate)
          "
            [nameDefault]="'add-company-page.address.country' | translate"
            [elements]="countries"
            [size]="'100%'"
            [color]="'#262A34'"
            (setItemFromButton)="setFoundersCitizenship(i, $event)"
            (settingDropdownOpen)="setDropdown($event)"
            [dropdownOpen]="dropdown == i + 1 ? true : false"
            [dropdown]="(i + 1).toString()"
          />-->
        </div>
        <div class="add-company-founders__input">
          <label class=""
            >{{'add-company-page.founders.Passport'| translate}}</label
          >
          <input type="text" formControlName="passport" [readonly]="i === 0 && directorsFormArray.value[0].founder"/>
        </div>
        <div class="add-company-founders__input-share">
          <label class="">{{'shares'| translate}}</label>
          <div class="add-company-founders__input-share__content">
            <input type="number" formControlName="share" min=0 max=100/>
            <p class="add-company-founders__input-share__content__text">%</p>
          </div>
        </div>
        <div *ngIf="shares >= 0" class="shares">
          <p class="shares__text">
            {{"add-company-page.availableshares" | translate}}
          </p>
          <p class="shares__content">{{shares}}%</p>
        </div>
        <div *ngIf="shares < 0" class="shares">
          <p style="color: red !important" class="shares__text">
            {{"add-company-page.impropershare" | translate}}
          </p>
        </div>
      </div>
      <div
        *ngIf="legalFoundersFormArray.value[i].legal"
        class="add-company-founders__content__founder__inputs"
      >
        <div class="add-company-founders__input">
          <label class=""
            >{{'add-company-page.founders.regCountry'| translate}}</label
          >
          <input type="text" formControlName="country" />
          <!--<app-dropdown-type-1
            [name]="
            legalFoundersFormArray.value[i].country
              ? getCountryName(legalFoundersFormArray.value[i].country)
              : ('add-company-page.address.country' | translate)
          "
            [nameDefault]="'add-company-page.address.country' | translate"
            [elements]="countries"
            [size]="'100%'"
            [color]="'#262A34'"
            (setItemFromButton)="setFoundersCountry(i, $event)"
            (settingDropdownOpen)="setDropdown($event)"
            [dropdownOpen]="dropdown == i + 1 + 'country' ? true : false"
            [dropdown]="(i + 1 + 'country').toString()"
          />-->
        </div>
        <div class="add-company-founders__input">
          <label class=""
            >{{'add-company-page.founders.legalForm'| translate}}</label
          >
          <!-- <app-dropdown-type-1
            [name]="
            legalFoundersFormArray.value[i].form
              ? legalFoundersFormArray.value[i].form
              : ('add-company-page.founders.addLegalForm' | translate)
          "
            [nameDefault]="'add-company-page.founders.addLegalForm' | translate"
            [elements]="legalForms"
            [size]="'100%'"
            [color]="'#262A34'"
            (setItemFromButton)="setFoundersForm(i, $event)"
            (settingDropdownOpen)="setDropdown($event)"
            [dropdownOpen]="dropdown == i + 1 + 'form' ? true : false"
            [dropdown]="(i + 1 + 'form').toString()"
          /> -->
          <input type="text" formControlName="form" />
        </div>
        <div class="add-company-founders__input">
          <label class=""
            >{{'add-company-page.founders.companyName'| translate}}</label
          >
          <input type="text" formControlName="name" />
        </div>
        <div class="add-company-founders__input">
          <label class=""
            >{{'add-company-page.founders.regNumber'| translate}}</label
          >
          <input type="text" formControlName="regNumber" />
        </div>
        <div class="add-company-founders__input-share">
          <label class="">{{'shares'| translate}}</label>
          <div class="add-company-founders__input-share__content">
            <input type="number" formControlName="share" min=1 max=100/>
            <p class="add-company-founders__input-share__content__text">%</p>
          </div>
        </div>
        <div *ngIf="shares >= 0" class="shares">
          <p class="shares__text">
            {{"add-company-page.availableshares" | translate}}
          </p>
          <p class="shares__content">{{shares}}%</p>
        </div>
        <div *ngIf="shares < 0" class="shares">
          <p style="color: red !important" class="shares__text">
            {{"add-company-page.impropershare" | translate}}
          </p>
        </div>
      </div>
      <!-- <button
        (click)="deleteFounder(i)"
        class="add-company-founders__dell-director-button"
      >
        {{'add-company-page.founders.delFounder' | translate}}
      </button> -->
    </div>
  </div>
  <!-- <button
    (click)="addFounder()"
    class="add-company-founders__add-director-button"
  >
    {{'add-company-page.founders.addFounder' | translate}}
  </button> -->

  <ng-content select="[price]"></ng-content>
  <button
    class="Button-blue add-company-page__submit-nonActive"
    [ngClass]="continueButton && shares === 0 ? 'add-company-page__submit' : ''"
    (click)="submitStructure()"
    [disabled]="(!continueButton) || (shares > 0)"
  >
    {{'continue-btn' | translate}}
  </button>
</div>

<button 
  type="button" 
  class="save-template-button"
  [ngClass]="!continueButton ? 'nonActive' : ''"
  (click)="submitStructure()" 
  [disabled]="!continueButton"
>{{ "add-company-page.saveToDrafts" | translate }}</button>
